<template>
  <div :class="classes.wrapper">
    <BaseIconButton
      :url="{ name: ROUTE_NAMES.FAVOURITE }"
      name="common:favourite"
      :width="28"
      :height="28"
      :count="userStore.favouriteDevices.length ?? undefined"
      class="hidden xl:grid"
      aria-label="Избранное"
    />
    <BaseIconButton
      :url="{ name: ROUTE_NAMES.COMPARISON }"
      :width="28"
      :height="28"
      name="common:comparison"
      :count="userStore.comparableDevices.length ?? undefined"
      class="hidden xl:grid"
      aria-label="Сравнение товаров"
    />
    <BaseIconButton
      v-if="!searchStore.isDisabled"
      url=""
      type="button"
      width="20"
      height="20"
      name="common:search"
      aria-label="Поиск"
      class="xl:hidden"
      @click="clickSearch"
    />
    <BaseIconButton
      type="a"
      href="https://lk.pedant.market/"
      target="_blank"
      :width="28"
      :height="28"
      name="common:person"
      aria-label="Личный кабинет"
      class="hidden xl:grid"
    />
    <BaseIconButton
      type="a"
      href="https://lk.pedant.market/"
      target="_blank"
      :width="20"
      :height="20"
      name="common:person"
      aria-label="Личный кабинет"
      class="xl:hidden w-fit"
    />
    <BaseIconButton
      :url="{ name: ROUTE_NAMES.CART }"
      :width="28"
      :height="28"
      name="common:cart-outline"
      :count="cartStore.device ? 1 : undefined"
      aria-label="Корзина"
      class="hidden xl:grid"
      @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_IN_HEADER)"
    />
    <BaseIconButton
      :url="{ name: ROUTE_NAMES.CART }"
      :width="20"
      :height="20"
      name="common:cart-outline"
      :count="cartStore.device ? 1 : undefined"
      aria-label="Корзина"
      class="xl:hidden w-fit"
      :class-counter="'right-[-4px]'"
      @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.CARD_IN_HEADER)"
    />
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'
import { emitYandexMetrika, YANDEX_METRIKA_GOALS } from '@/utils'

const emit = defineEmits<{
  handleClick: [payload: string]
}>()

const classes = {
  wrapper: cx(['grid', 'grid-flow-col', 'gap-x-1', 'xl:gap-x-[18px]']),
}

const cartStore = useCartStore()
const userStore = useUserStore()
const searchStore = useSearchStore()

const clickSearch = () => {
  emit('handleClick', '')
}
</script>