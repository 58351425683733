<template>
  <nav :class="classes.navigation">
    <ul :class="classes.wrapper">
      <li
        v-for="(link, index) in links"
        :key="index"
        :class="classes.navigationItem"
      >
        <BaseButton
          v-if="link.to"
          type="nuxt-link"
          :url="
            Object.values(ROUTE_NAMES).includes(link.to)
              ? { name: link.to }
              : link.to
          "
          @click="link.reachGoal()"
        >
          {{ link.name }}
          <Icon
            v-if="link.name === 'Распродажа'"
            name="stoppers:flame-red"
            size="16"
            class="ml-[8px] relative top-[3px]"
          />
        </BaseButton>
        <WidgetMainMenuDropdown
          v-else-if="link.items"
          :name="link.name"
          :items="link.items"
          :catalog-icon="link.name === 'Каталог'"
          :is-right-position="index === links.length - 1"
          :class="classes.navigationItemDropdown"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { cx } from 'cva'
import { MODEL_GROUP_NAME, ROUTE_NAMES } from '@/constants'

const classes = {
  navigation: cx([
    'backdrop-blur-xl',
    'bg-blend-multiply',
    'bg-bg-main-primary',
    'sticky',
    'z-[2]',
  ]),
  wrapper: cx([
    'w-full',
    'flex',
    'items-center',
    'py-[10px]',
    'whitespace-nowrap',
    'container',
    'justify-between',
    'xl:justify-start',
  ]),
  navigationItem: cx([
    'xl:mr-9',
    'last:mr-0',
    'xl:last:ml-auto',
    'xl:font-semibold',
    'text-primary',
    'hover:text-accent-hover',
    'transition',
    'duration-300',
  ]),
  navigationItemDropdown: cx([
    'mr-9',
    'last:mr-0',
    'last:ml-auto',
    'font-semibold',
    'text-primary',
    'transition',
    'duration-300',
  ]),
}

const otherLinks = [
  {
    name: 'Продать смартфон',
    to: ROUTE_NAMES.TRADE_IN,
    reachGoal: () => {},
  },
  {
    name: 'Распродажа',
    to: useRuntimeConfig().app.baseURL + 'phone-i?c_sale_buyout=1',
    reachGoal: () => {},
  },
  {
    name: 'Ещё',
    items: [
      {
        name: 'О подписке',
        to: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
        reachGoal: () => {},
      },
      {
        name: 'Как оплатить и получить',
        to: ROUTE_NAMES.PAYMENT,
        reachGoal: () => {},
      },
      {
        name: 'Гарантия',
        to: ROUTE_NAMES.GUARANTEE,
        reachGoal: () => {},
      },
      {
        name: 'Возврат',
        to: ROUTE_NAMES.RETURN,
        reachGoal: () => {},
      },
      {
        name: 'Адреса СЦ для гарантийного ремонта',
        to: ROUTE_NAMES.GARANTIA_SERVICE,
        reachGoal: () => {},
      },
      {
        name: 'О компании',
        to: ROUTE_NAMES.COMPANY,
        reachGoal: () => {},
      },
      {
        name: 'Контакты',
        to: ROUTE_NAMES.CONTACTS,
        reachGoal: () => {},
      },
    ],
  },
]

const modelGroupsStore = useModelGroupsStore()
const { list: categories } = storeToRefs(modelGroupsStore)

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const desktopLinks = computed(() => {
  if (!xlAndLarger.value) return []
  const catalogItems = categories.value
    .filter(s => s.models.length)
    .map((category) => {
      return {
        name: category.name,
        to:
          category.url === '/phone-i'
            ? category.url + '?c_subscription=1'
            : category.url,
        items: [],
        reachGoal: () => {
          emitYandexMetrika(YANDEX_METRIKA_GOALS.VKATALOG_KATEGORII_HEADER)

          if (category.name === MODEL_GROUP_NAME.IPHONE)
            emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE)
          else if (category.name === MODEL_GROUP_NAME.SAMSUNG)
            emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_SAMSUNG_IZ_SHAPKI)
          else if (category.name === MODEL_GROUP_NAME.HUAWEI)
            emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_HUAWEI_IZ_SHAPKI)
          else if (category.name === MODEL_GROUP_NAME.XIAOMI)
            emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_XIAOMI_IZ_SHAPKI)
        },
      }
    })

  return [
    {
      name: 'Каталог',
      to: ROUTE_NAMES.CATALOG_CATEGORIES,
      items: [],
      reachGoal: () =>
        emitYandexMetrika(YANDEX_METRIKA_GOALS.VESKATALOG_HEADER),
    },
    ...catalogItems,
    ...otherLinks,
  ]
})

const mobileLinks = computed(() => {
  if (xlAndLarger.value) return []
  const catalogItems = categories.value?.map((category) => {
    return {
      name: category.name,
      to:
        category.url === '/phone-i'
          ? category.url + '?c_subscription=1'
          : category.url,
      disabled: !category.models.length,
      reachGoal: () => {
        emitYandexMetrika(YANDEX_METRIKA_GOALS.VKATALOG_KATEGORII_HEADER)

        if (category.name === MODEL_GROUP_NAME.IPHONE)
          emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_IPHONE)
        else if (category.name === MODEL_GROUP_NAME.SAMSUNG)
          emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_SAMSUNG_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.HUAWEI)
          emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_HUAWEI_IZ_SHAPKI)
        else if (category.name === MODEL_GROUP_NAME.XIAOMI)
          emitYandexMetrika(YANDEX_METRIKA_GOALS.CATALOG_XIAOMI_IZ_SHAPKI)
      },
    }
  })

  catalogItems?.push({
    name: 'Весь каталог',
    to: ROUTE_NAMES.CATALOG_CATEGORIES,
    disabled: false,
    reachGoal: () => emitYandexMetrika(YANDEX_METRIKA_GOALS.VESKATALOG_HEADER),
  })

  return [
    {
      name: 'Каталог',
      items: catalogItems,
      reachGoal: () => {},
    },
    {
      name: 'О подписке',
      to: ROUTE_NAMES.SUBSCRIPTION_DESCRIPTION,
      reachGoal: () =>
        emitYandexMetrika(YANDEX_METRIKA_GOALS.PODPISKA_GLAVNAYA_CTA_V3),
    },
    {
      name: 'Продать смартфон',
      to: ROUTE_NAMES.TRADE_IN,
      reachGoal: () => {},
    },
  ]
})

const links = computed(() =>
  xlAndLarger.value ? desktopLinks.value : mobileLinks.value,
)
</script>