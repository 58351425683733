<template>
  <component
    :is="finalType"
    :class="`${text ? classes.link : 'group'}`"
    :to="url ? url : null"
    :href="finalHref"
    :target="target ? target : null"
    :aria-label="ariaLabel ? ariaLabel : null"
  >
    <div :class="classes.iconWrapper">
      <Icon
        :name="name"
        :size="width"
        :class="`${classes.icon} ${className}`"
        :style="`width:${width}px`"
      />
      <ClientOnly>
        <span
          v-if="count"
          :class="`${classes.count} ${classCounter}`"
        >{{ count }}</span>
      </ClientOnly>
    </div>
    <p
      v-if="text"
      :class="classes.text"
    >
      {{ text }}
    </p>
  </component>
</template>

<script setup lang="ts">
import { cx } from 'cva'

const props = withDefaults(
  defineProps<Props>(),
  {
    type: 'NuxtLink',
    name: '',
    width: '',
    height: '',
    url: '', // for NuxtLink
    text: '',
    count: 0,
    className: '',
    classCounter: '',
    ariaLabel: '',
    href: '', // for link
    target: '', // for link
  },
)

const classes = {
  link: cx([
    'grid',
    'grid-flow-col',
    'auto-cols-min',
    'gap-x-2',
    'items-center',
    'group',
  ]),
  iconWrapper: cx([
    'relative',
    'flex',
    'duration-300',
    'group-hover:bg-snow-white-400',
    'group-active:bg-snow-white-500',
    'p-1',
    'rounded-lg',
  ]),
  icon: cx([
    'text-icon-primary-on-color',
    'cursor-pointer',
  ]),
  text: cx([
    'text-secondary',
    'group-hover:text-primary-on-color',
    'text-sm/[120%]',
    'duration-300',
  ]),
  count: cx([
    'absolute',
    'top-0',
    'right-0',
    'flex',
    'justify-center',
    'items-center',
    'w-3',
    'h-3',
    'p-2',
    'text-primary-on-color',
    'text-[10px]',
    'font-bold',
    'leading-[130%]',
    'bg-bg-red-error',
    'rounded-full',
  ]),
}

interface Props {
  type?: string // a | NuxtLink | button
  name: string
  width: string | number
  height: string | number
  url?: string | { name: string } // for NuxtLink
  text?: string
  count?: number
  className?: string
  classCounter?: string
  ariaLabel?: string
  href?: string // for a
  target?: string // for a
}

const isNuxtLink = () => props.type === 'NuxtLink'

const finalType = computed(() => {
  if (isNuxtLink()) return defineNuxtLink({})

  return props.type
})

const finalHref = computed(() => {
  if (isNuxtLink()) return

  return props.href
})
</script>