<template>
  <div :class="defaultClasses">
    <WidgetSaleBanner v-if="isHomePage" />
    <BasicLayoutHeader />

    <main>
      <slot></slot>
      <WidgetSectionSeoContent />
    </main>

    <BasicLayoutFooter v-if="!isTradeInPage" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import type { MetaObject } from 'nuxt/schema'
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'

const defaultClasses = cx([
  'text-secondary',
  'flex',
  'flex-col',
  'justify-between',
  'min-h-screen',
])

const route = useRoute()
const seoContentStore = useSeoContentStore()
const catalogDeviceStore = useCatalogDeviceStore()
const abStore = useAbStore()

const isHomePage = computed(() => route.name === 'index')
const isCatalogDevicePage = computed(() => route.name === ROUTE_NAMES.CATALOG_DEVICE)
const isTradeInPage = computed(() => route.name === ROUTE_NAMES.TRADE_IN)

const meta = computed(() => {
  const result: {
    title?: string
    meta?: { name: string, content: string }
  } = {}

  if (Array.isArray(seoContentStore.list) && seoContentStore.list.length) {
    if (seoContentStore.list[0].Title) {
      result.title = seoContentStore.list[0].Title
    }

    if (seoContentStore.list[0].MetaDescription) {
      result.meta = {
        name: 'description',
        content: seoContentStore.list[0].MetaDescription,
      }
    }
  }

  if (isCatalogDevicePage.value && catalogDeviceStore.item) {
    result.title = `${catalogDeviceStore.item.FullName} | Выгодные цены в маркетплейсе Pedant.Market`
  }

  return result
})

useHead(computed(() => meta.value as MetaObject))

onMounted(() => {
  abStore.isLoading = true
  abStore.initAllExperiments()
  abStore.isLoading = false
})
</script>